import { axios } from "@/api/request";

export function info(parameter) {
  return axios({
    url: "/justap/taginfo",
    method: "get",
    params: parameter,
  });
}

export function vcard(parameter) {
  return axios({
    url: "/info/vcard",
    method: "post",
    data: parameter,
  });
}

export function login(parameter) {
  return axios({
    url: "/user/login",
    method: "post",
    data: parameter,
  });
}

export function reg(parameter) {
  return axios({
    url: "/user/reg",
    method: "post",
    data: parameter,
  });
}

export function bind(parameter) {
  return axios({
    url: "/user/bind",
    method: "post",
    data: parameter,
  });
}

export function userexist(parameter) {
  return axios({
    url: "/info/userexist",
    method: "post",
    data: parameter,
  });
}

export function userupdate(parameter) {
  return axios({
    url: "/user/update",
    method: "post",
    data: parameter,
  });
}

export function avatar(parameter) {
  return axios({
    url: "/user/avatar",
    method: "post",
    data: parameter,
  });
}

export function userinfo(parameter) {
  return axios({
    url: "/user/info",
    method: "post",
    data: parameter,
  });
}

export function feed(parameter) {
  return axios({
    url: "https://pregod.rss3.dev/v1.0.0/notes/" + parameter,
    method: "get",
  });
}

export function nfts(parameter) {
  return axios({
    url: "https://pregod.rss3.dev/v1.1.0/assets/" + parameter,
    method: "get",
  });
}

export function domains(parameter) {
  return axios({
    url: "https://rss3.domains/name/" + parameter,
    method: "get",
  });
}