import Vue from "vue";
import axios from "axios";
const VueAxios = {
  vm: {},
  // eslint-disable-next-line no-unused-vars
  install(Vue, instance) {
    if (this.installed) {
      return;
    }
    this.installed = true;

    if (!instance) {
      // eslint-disable-next-line no-console
      console.error("You have to install axios");
      return;
    }

    Vue.axios = instance;

    Object.defineProperties(Vue.prototype, {
      axios: {
        get: function get() {
          return instance;
        },
      },
      $http: {
        get: function get() {
          return instance;
        },
      },
    });
  },
};

Vue.use(VueAxios, axios);
axios.defaults.headers.common["token"] = localStorage.getItem("token");

const service = axios.create({
   baseURL: 'https://uni-prod.sodaex.press/',
  // baseURL: "http://127.0.0.1:8360",
  timeout: 8000, // 请求超时时间
});

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service);
  },
};
export { installer as VueAxios, service as axios };
